import { AnyAction, combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./user";
import alertReducer from "./alert";
import schoolReducer from "./school";
import studentReducer from "./student";
import bookingReducer from "./booking";
import termReducer from "./term";
import attendanceReducer from "./attendance";
import invoiceReducer from "./invoice";
import bankingReducer from "./banking";
import blogReducer from "./blog";
import walletReducer from "./wallet";
import morningBookinReducer from "./morningBooking";
import staffRoasterReducer from "./staffRoaster";

const combinedReducer = combineReducers({
  users: userReducer,
  alert: alertReducer,
  schools: schoolReducer,
  students: studentReducer,
  bookings: bookingReducer,
  terms: termReducer,
  attendances: attendanceReducer,
  invoices: invoiceReducer,
  banking: bankingReducer,
  blogs: blogReducer,
  wallet: walletReducer,
  morningBookings: morningBookinReducer,
  staffRoaster: staffRoasterReducer,
});

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === "users/reset") {
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
