import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../modules/hooks/store";
import { getStudents, isLoading, resetPupilBooking, studentsData } from "../../slices/booking";
import { lazy, memo, Suspense, useCallback, useEffect, useMemo, useState } from "react";
import PageLoadingSpinner from "../PageLoadingSpinner";
import { getTerms } from "../../slices/term";
import { getParentInvoice, parentInvoice as invoiceData } from "../../slices/invoice";
import LockTwoToneIcon from "@mui/icons-material/LockTwoTone";
import { formatNumber } from "../../utils";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { Datum, IWalletTransactionTypeEnum } from "../../slices/interfaces";
import { analytics } from "../../config/firebase";
import { logEvent } from "firebase/analytics";
import { getWalletTransactions, isLoadingWalletTransactions, userWalletTransactions } from "../../slices/wallet";
import { selectUser } from "../../slices/user";
import { format } from "date-fns";
import ComponentLoadingSpinner from "../ComponentLoadingSpinner";
import "../wallet/wallet.css";

const PupilBooking = lazy(() => import("./PupilBooking"));
const Receipt = lazy(() => import("../pdf/receipt"));

type monthCost = {
  fullDay: number;
  term: number;
};
export function ClientBooking() {
  const loading = useAppSelector(isLoading);
  const parentInvoice = useAppSelector(invoiceData);
  const dispatch = useAppDispatch();
  const students = useAppSelector(studentsData);
  const [openPupilModal, setOpenPupilModal] = useState<boolean>(false);
  const [pupilId, setPupilId] = useState<string>("");
  const [openReceiptModal, setOpenReceiptModal] = useState<boolean>(false);
  const isLoadingTransactions = useAppSelector(isLoadingWalletTransactions);
  const walletTransactions = useAppSelector(userWalletTransactions);
  const userData = useAppSelector(selectUser);

  const [receiptData, setReceiptData] = useState<Datum & monthCost>();
  logEvent(analytics, "wClientBooking", {
    firebase_screen_class: "wClientBooking",
    page_title: "ClientBooking",
  });

  const handlePupilModalClose = (): void => {
    setOpenPupilModal(!openPupilModal);
    setPupilId("");
    dispatch(resetPupilBooking());
  };
  const openPupilBooking = useCallback((id: string) => {
    setPupilId(id);
    setOpenPupilModal(true);
  }, []);

  useEffect(() => {
    dispatch(getStudents());
    dispatch(getTerms());
    dispatch(getParentInvoice());
    dispatch(getWalletTransactions({ userId: userData?._id!, excludeDeleted: true }));
  }, [dispatch, userData?._id]);

  function _downloadReceipt(pupilInvoice: Datum, fullDay: number, term: number): void {
    setReceiptData({ ...pupilInvoice, fullDay, term });
    setOpenReceiptModal(true);
  }

  const handleReceiptModalClose = (): void => {
    setOpenReceiptModal(!openReceiptModal);
    setReceiptData(undefined);
  };

  const calculateBalance = useMemo(() => {
    let balance = 0;
    if (walletTransactions) {
      balance = walletTransactions.reduce((acc, transaction) => {
        const { transactionType, amount } = transaction;
        return transactionType === IWalletTransactionTypeEnum.CREDIT
          ? acc + parseFloat(amount)
          : acc - parseFloat(amount);
      }, 0);
    }
    return balance;
  }, [walletTransactions]);

  return (
    <>
      {loading ? (
        <PageLoadingSpinner />
      ) : (
        <Container maxWidth={false} sx={{ height: "90vh", textAlign: "center" }}>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={4} rowSpacing={2} container height={"fit-content"}>
              <Grid item container xs={12} justifyContent={"center"}>
                <Typography variant="h6">Wallet</Typography>
                <Card
                  sx={{
                    minWidth: "100%",
                    height: "190px",
                    background: "linear-gradient(90deg, rgba(20,117,91,1) 0%, rgba(9,178,251,1) 100%)",
                    borderRadius: "20px",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <CardContent>
                    <Typography variant="body2" sx={{ color: "white", fontSize: 100 }}>
                      {formatNumber(calculateBalance)}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item container sx={{ maxHeight: "20px" }} justifyContent={"center"}>
                <Typography variant="h6">Recent Wallet Transactions</Typography>
                {isLoadingTransactions ? (
                  <Box display={"flex"} alignItems={"center"} height={"300px"} justifyContent={"center"} width={"100%"}>
                    <ComponentLoadingSpinner height="400px" />
                  </Box>
                ) : (
                  <TableContainer component={Paper} sx={{ maxHeight: "53vh" }}>
                    <Table
                      stickyHeader
                      aria-label="simple table"
                      size="small"
                      sx={{
                        td: {
                          fontSize: "14px",
                          py: "10px",
                        },
                        th: {
                          backgroundColor: "#14e6dd",
                        },
                      }}
                    >
                      <TableHead>
                        <TableRow sx={{ backgroundColor: "#D6EEEE" }}>
                          <TableCell>Date</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell align="right">Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {walletTransactions &&
                          [...walletTransactions].map((row, i) => (
                            <TableRow
                              key={row._id}
                              sx={{
                                "&:last-child td, &:last-child th": { border: 0 },
                                td: {
                                  color: row.transactionType === IWalletTransactionTypeEnum.DEBIT ? "red" : "black",
                                },
                              }}
                            >
                              <TableCell>{format(new Date(row.createdAt!), "dd/MM/yy")}</TableCell>
                              <TableCell>{row.description}</TableCell>
                              <TableCell>{row.transactionType}</TableCell>
                              <TableCell align="right">{formatNumber(parseFloat(row.amount) ?? 0)}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid item xs={12}>
                <Typography variant="h6">Term Bookings</Typography>
                <TableContainer component={Paper}>
                  <Table
                    aria-label="client"
                    size="small"
                    sx={{
                      th: {
                        backgroundColor: "#14e6dd",
                      },
                      "td:nth-of-type(4), th:nth-of-type(4)": {
                        textAlign: "center",
                      },
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Surname</TableCell>
                        <TableCell>School</TableCell>
                        <TableCell width={"20%"}>Booking</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {students &&
                        students.map((student) => (
                          <TableRow
                            key={student._id}
                            sx={{
                              bgcolor: student.isActive ? "" : "#ffe9ec",
                              color: "green",
                            }}
                          >
                            <TableCell>{student.firstName}</TableCell>
                            <TableCell>{student.lastName}</TableCell>
                            <TableCell>{student.school.name}</TableCell>
                            <TableCell>
                              <Button
                                variant="contained"
                                color="success"
                                size="small"
                                disabled={!student.isActive}
                                sx={{
                                  m: "0px !important",
                                  fontSize: "10px",
                                  width: student.isActive ? "fit-content" : "150px",
                                }}
                                onClick={() => openPupilBooking(student._id!)}
                              >
                                {!student.isActive && <LockTwoToneIcon color="error" sx={{ fontSize: "12px" }} />}{" "}
                                {student.isActive ? "View or Manage Booking" : "Contact admin"}
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} height={"90vh"}>
                <Typography variant="h6">Invoices for the last six months</Typography>
                <Box component={Paper}>
                  <TableContainer component={Paper}>
                    <Table
                      aria-label="client"
                      size="small"
                      sx={{
                        th: {
                          backgroundColor: "#14e6dd",
                        },
                        "td:nth-of-type(n+3)": {
                          textAlign: "right",
                        },
                        "th:nth-of-type(n+3)": {
                          textAlign: "right",
                        },
                        "th:last-child": {
                          textAlign: "center",
                        },
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Period</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Term time</TableCell>
                          <TableCell>Holidays</TableCell>
                          <TableCell>Travel</TableCell>
                          <TableCell>Discount</TableCell>
                          <TableCell>Part Payment</TableCell>
                          <TableCell>Total</TableCell>
                          <TableCell>Payment status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {parentInvoice.length > 0 &&
                          parentInvoice.map((monthInvoice) => {
                            return monthInvoice.data.map((pupilInvoice) => {
                              const normalDaysIn = pupilInvoice.record.filter((day) => !day.fullDay).length;
                              const fullDaysIn = pupilInvoice.record.length - normalDaysIn;
                              const total =
                                normalDaysIn * monthInvoice.normalCharge +
                                fullDaysIn * monthInvoice.fullDayCharge +
                                (pupilInvoice.travel ?? 0);

                              return (
                                <TableRow key={`${pupilInvoice.pupilId}_${monthInvoice.period}`}>
                                  <TableCell>{monthInvoice.period}</TableCell>
                                  <TableCell>{pupilInvoice.firstName}</TableCell>
                                  <TableCell>
                                    {normalDaysIn !== 0
                                      ? `${normalDaysIn} * ${monthInvoice.normalCharge}`
                                      : normalDaysIn}
                                  </TableCell>
                                  <TableCell>
                                    {fullDaysIn !== 0 ? `${fullDaysIn} * ${monthInvoice.fullDayCharge}` : fullDaysIn}
                                  </TableCell>
                                  <TableCell>{formatNumber(pupilInvoice.travel ?? 0)}</TableCell>
                                  <TableCell>{formatNumber(pupilInvoice.discount)}</TableCell>
                                  <TableCell>{formatNumber(pupilInvoice.partialPayment)}</TableCell>
                                  <TableCell>{`${formatNumber(
                                    total - pupilInvoice.discount - pupilInvoice.partialPayment
                                  )}`}</TableCell>
                                  <TableCell>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                      {pupilInvoice.paid ? "Paid" : "Outstanding"}
                                      <IconButton
                                        aria-label="delete"
                                        size="small"
                                        onClick={
                                          pupilInvoice.paid
                                            ? () =>
                                                _downloadReceipt(
                                                  pupilInvoice,
                                                  monthInvoice.fullDayCharge,
                                                  monthInvoice.normalCharge
                                                )
                                            : undefined
                                        }
                                      >
                                        <ReceiptIcon
                                          fontSize="inherit"
                                          color={pupilInvoice.paid ? "success" : "disabled"}
                                        />
                                      </IconButton>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              );
                            });
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )}
      <Suspense fallback={<PageLoadingSpinner />}>
        {openPupilModal && <PupilBooking id={pupilId} handleClose={handlePupilModalClose} open={openPupilModal} />}
      </Suspense>

      <Suspense fallback={<PageLoadingSpinner />}>
        {openReceiptModal && (
          <Receipt pupilData={receiptData} handleClose={handleReceiptModalClose} open={openReceiptModal} />
        )}
      </Suspense>
    </>
  );
}

export default memo(ClientBooking);
