import { useAppDispatch, useAppSelector } from "../../modules/hooks/store";
import { allUsers, getUserWallet, isLoadingParents } from "../../slices/wallet";
import {
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowParams,
  GridRowsProp,
  GridSelectionModel,
} from "@mui/x-data-grid";
import { Suspense, lazy, useContext, useEffect, useState } from "react";
import { AccountTypeEnum, IAuth, IDbUser } from "../../slices/interfaces";
import { Chip, Container, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../modules/context";
import { analytics } from "../../config/firebase";
import { logEvent } from "firebase/analytics";
import PageLoadingSpinner from "../PageLoadingSpinner";
import { formatNumber } from "../../utils";
import { CustomizedDataGrid } from "../styledComponent";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import AddCardIcon from "@mui/icons-material/AddCard";

const WalletDetails = lazy(() => import("./WalletDetails"));
const EditWallet = lazy(() => import("./EditWallet"));

const WalletDashboard = () => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector(allUsers);
  const loadingParents = useAppSelector(isLoadingParents);
  const { dbUser } = useContext(AuthContext) as IAuth;
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedClient, setSelectedClient] = useState<IDbUser | string[]>();
  const [openEditBalanceModal, setOpenEditBalanceModal] = useState<boolean>(false);
  const [creditMultiple, setCreditMultiple] = useState<GridSelectionModel>([]);

  logEvent(analytics, "wWalletDashboard", {
    firebase_screen_class: "WalletDashboard",
    page_title: "WalletDashboard",
  });

  const handleCloseModal = (): void => {
    setOpenModal(!openModal);
  };

  const _handleCloseEditWalletModal = (): void => {
    setOpenEditBalanceModal((prev) => !prev);
  };

  useEffect(() => {
    dispatch(getUserWallet());
    if (dbUser && dbUser.accountType !== AccountTypeEnum.ADMIN) {
      navigate("/", { replace: true });
    }
  }, [dispatch, dbUser, navigate]);

  const rows: GridRowsProp<IDbUser> = userData!;
  const columns: GridColumns = [
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
    },
    {
      field: "balance",
      headerName: "Balance",
      align: "right",
      headerAlign: "right",
      flex: 1,
      renderCell: (params: GridRenderCellParams<IDbUser>) => (
        <Typography fontSize={"inherit"} textAlign={"right"}>
          {formatNumber(params.row.balance)}
        </Typography>
      ),
    },
    {
      field: "actions",
      type: "actions",
      width: 90,
      getActions: (params: GridRowParams<IDbUser>) => [
        <GridActionsCellItem
          icon={<FormatListNumberedIcon color="primary" />}
          label="Transactions"
          onClick={() => {
            setOpenModal(!openModal);
            setSelectedClient(params.row);
          }}
        />,
        <GridActionsCellItem
          icon={<AddCardIcon color="secondary" />}
          label="Credit or Debit Wallet"
          disabled={params.row.accountType === AccountTypeEnum.LOCK}
          onClick={() => {
            setOpenEditBalanceModal((prev) => !prev);
            setSelectedClient(params.row);
            setCreditMultiple([]);
          }}
        />,
      ],
    },
  ];

  return (
    <Container>
      <Grid container maxWidth={"lg"}>
        <Grid item xs={12} mt={1} mb={1}>
          <Chip
            label={`Add credit to ${creditMultiple.length} clients`}
            color="error"
            icon={<FormatListNumberedIcon color="error" />}
            disabled={creditMultiple.length === 0}
            onClick={() => {
              setOpenEditBalanceModal((prev) => !prev);
              setSelectedClient(creditMultiple as string[]);
            }}
          />
        </Grid>
        <Grid item xs={12} height={"90vh"}>
          <CustomizedDataGrid
            checkboxSelection
            rows={rows}
            columns={columns}
            // selectionModel={}
            onSelectionModelChange={(newRowSelectionModel) => {
              setCreditMultiple(newRowSelectionModel);
            }}
            isRowSelectable={(params: GridRowParams) => params.row.accountType !== AccountTypeEnum.LOCK}
            selectionModel={creditMultiple}
            rowHeight={35}
            loading={loadingParents}
            headerHeight={38}
            getRowId={(row) => row._id}
            sx={{ width: "100%", bgcolor: "white" }}
            getRowClassName={(params) => (params.row.accountType === AccountTypeEnum.LOCK ? `account-locked` : "")}
          />
        </Grid>
        <Suspense fallback={<PageLoadingSpinner />}>
          {openModal && selectedClient && (
            <WalletDetails open={openModal} handleClose={handleCloseModal} client={selectedClient as IDbUser} />
          )}
        </Suspense>
        <Suspense fallback={<PageLoadingSpinner />}>
          {openEditBalanceModal && selectedClient && (
            <EditWallet open={openEditBalanceModal} handleClose={_handleCloseEditWalletModal} client={selectedClient} />
          )}
        </Suspense>
      </Grid>
    </Container>
  );
};

export default WalletDashboard;
