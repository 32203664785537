import axios from "axios";
import {
  ISchool,
  IStudent,
  IDbUser,
  studentType,
  IStudentData,
  IEvent,
  ITerm,
  IPupilBooking,
  ICurrentMonthBooking,
  IAttendanceData,
  IInvoice,
  ITransactionCategory,
  IBankTransactionData,
  IGroupedTransactions,
  IFinancialData,
  ICategorizedBankTransactionData,
  IBlog,
  IStaffRoasterMonthBooking,
  IBreakfastClubMonthHoliday,
  IWalletTransaction,
} from "../../slices/interfaces";
import { auth } from "../../config/firebase";

axios.defaults.baseURL =
  process.env.NODE_ENV === "development" ? process.env.REACT_APP_BASE_URL_DEV : process.env.REACT_APP_BASE_URL_PROD;

axios.interceptors.request.use((config) => {
  return new Promise(async (resolve, _) => {
    config.headers!.Authorization = `Bearer ${await auth.currentUser?.getIdToken()}`;
    process.env.NODE_ENV !== "development"
      ? resolve(config)
      : setTimeout(() => {
          resolve(config);
        }, 1000);
  });
});

axios.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    return Promise.reject<string>(error.response.data.extra.message ?? error.response.data.message);
  }
);

const dateConverter = (date: Date): string => {
  return date.toDateString();
};

export class Api {
  static getSchoolManagers() {
    return axios.get<never, IDbUser[]>("/users/allUsers?managers=true");
  }
  static createBlog(data: IBlog) {
    return axios.post<never, IBlog>(`/blogs`, {
      data,
    });
  }
  static getBlogs(blogId?: string) {
    return axios.get<never, IBlog[]>(`/blogs`, {
      params: { blogId },
    });
  }
  static deleteAttendance(pupilId: string, dayOfMonth: string | undefined) {
    return axios.delete<never, IAttendanceData[]>(`/attendance`, {
      data: {
        pupilId,
        dayOfMonth,
      },
    });
  }

  static downloadBankTransactions(data: { period: string }) {
    return axios.get<never, ICategorizedBankTransactionData[]>(`/banking/downloadBankTransactions`, {
      params: { ...data },
    });
  }
  static updateInvoice(data: {
    pupilId: string;
    month: number;
    year: number;
    discount: number;
    partialPayment: number;
  }) {
    return axios.patch<never, IInvoice[]>("/invoices/invoice", {
      ...data,
    });
  }
  static historicFinancialStat() {
    return axios.get<never, IFinancialData[]>(`/banking/historicFinancialStat`);
  }
  static editTransactionCategory(data: { category: string; transactionId: string }) {
    return axios.patch<never, void>("/banking/editTransactionCategory", { ...data });
  }

  static groupedPeriodTransactions() {
    return axios.get<never, IGroupedTransactions[]>(`/banking/groupedPeriodTransactions`);
  }
  static getBankTransaction(period: number, invoice?: boolean) {
    return axios.get<never, { transactionDate: string; transactions: IBankTransactionData[] }[]>(
      `/banking/getBankTransaction`,
      {
        params: { period, invoice },
      }
    );
  }
  static uploadBankTransaction(transactions: IBankTransactionData[]) {
    return axios.post<never, void>(`/banking/uploadBankTransaction`, {
      transactions,
    });
  }
  static getTransactionCategory(transactionCategoryId?: string) {
    return axios.get<never, ITransactionCategory[]>(`/banking/transactionCategory`, {
      params: { transactionCategoryId },
    });
  }
  static createTransactionCategory(data: ITransactionCategory) {
    return axios.post<never, any[]>(`/banking/transactionCategory`, {
      ...data,
    });
  }
  static parentInvoice() {
    return axios.get<never, IInvoice[]>(`/invoices/parentInvoice`);
  }
  static adminUpdateUserAccountType(_data: IDbUser) {
    return axios.put<never, IDbUser[]>("/users/adminUpdateUserAccountType", _data);
  }
  static sendInvoice(pupilId: string | undefined, month: number, year: number) {
    return axios.post<never, void>("/invoices/sendInvoice", { pupilId, month, year });
  }

  static deleteInvoice(pupilId: string, month: number, year: number) {
    return axios.post<never, IInvoice[]>("/invoices/deleteInvoice", { pupilId, month, year });
  }

  static updatePaidStatus(pupilId: string, paidStatus: boolean, month: number, year: number) {
    return axios.patch<never, IInvoice[]>("/invoices", { pupilId, paidStatus, month, year });
  }
  static getInvoice(month: number, year: number) {
    return axios.get<never, IInvoice[]>(`/invoices`, {
      params: { month, year },
    });
  }
  static createInvoice(normalCharge: number, fullDayCharge: number, breakfastClubCharge?: number) {
    return axios.post<never, void>(`/invoices`, {
      normalCharge,
      fullDayCharge,
      breakfastClubCharge,
    });
  }
  static signOutAttendance(pupilId: string) {
    return axios.patch<never, IAttendanceData[]>(`/attendance`, { pupilId });
  }
  static getAttendance(month: boolean, pupilId?: string) {
    return axios.get<never, IAttendanceData[]>(`/attendance`, {
      params: { month, pupilId },
    });
  }

  static getBreakFastAttendance() {
    return axios.get<never, IBreakfastClubMonthHoliday>("/breakfastClub/?today=false");
  }

  static updateBreakFastAttendance(pupilId: string, date: string, isSignedIn: boolean) {
    return axios.patch<never, IBreakfastClubMonthHoliday>("/breakfastClub/", {
      pupilId,
      date,
      isSignedIn,
    });
  }

  static updateMorningBooking(pupilId: string, date: string, isSignedIn: boolean) {
    return axios.patch<never, IBreakfastClubMonthHoliday>("/morningBooking/", {
      pupilId,
      date,
      isSignedIn,
    });
  }

  static getMorningBooking() {
    return axios.get<never, IBreakfastClubMonthHoliday>("/morningBooking/?today=false");
  }

  static getStaffRoaster() {
    return axios.get<never, IStaffRoasterMonthBooking>("/staffRoaster/?today=false");
  }

  static updateStaffRoaster(staffId: string, date: string, isSignedIn: boolean) {
    return axios.patch<never, IStaffRoasterMonthBooking>("/staffRoaster/", {
      staffId,
      date,
      isSignedIn,
    });
  }

  static async createAttendance(pupilId: string, dayOfMonth?: string, fullDay = false) {
    return axios.post<never, IAttendanceData[]>(`/attendance`, {
      pupilId,
      dayOfMonth,
      fullDay,
    });
  }
  static async getTodayBooking(month: boolean) {
    return axios.get<never, ICurrentMonthBooking[]>(`/bookings/todayBooking/?month=${month}`);
  }
  static createBooking(data: IPupilBooking) {
    const convertDate = {
      ...data,
      termStart: dateConverter(data.termStart),
      termEnd: dateConverter(data.termEnd),
    };
    return axios.post<never, void>(`/bookings`, convertDate);
  }
  static getPupilBooking(pupilId: string) {
    return axios.get<never, IPupilBooking[]>(`/bookings/${pupilId}`);
  }
  static deleteTerm(id: string) {
    return axios.delete<never, ITerm[]>("/terms/", { data: { id } });
  }
  static async editTerm(data: Omit<ITerm, "termStart |termEnd">) {
    return axios.patch<never, ITerm[]>("/terms", data);
  }
  static async createTerm(data: ITerm) {
    const convertDate = {
      ...data,
      termStart: dateConverter(data.termStart),
      termEnd: dateConverter(data.termEnd),
    };
    return axios.post<never, ITerm[]>("/terms", convertDate);
  }
  static async getTerms() {
    return axios.get<never, ITerm[]>("/terms");
  }

  static deleteSchool(schoolId: string) {
    return axios.delete<never, ISchool[]>("/schools/", { data: { schoolId } });
  }
  static editSchool(schoolData: ISchool) {
    return axios.post<never, ISchool[]>("/schools", schoolData);
  }
  static getSchools() {
    return axios.get<never, ISchool[]>("/schools");
  }

  static async getBookings() {
    return axios.get<never, { result: IStudentData[]; bankHolidays: IEvent[] }>("/bookings");
  }

  static async getUsers() {
    return axios.get<never, IDbUser[]>("/users");
  }

  static async getAllUsers() {
    return axios.get<never, IDbUser[]>("/users/allUsers");
  }

  static async getUserWallet(wallet?: boolean) {
    return axios.get<never, IDbUser[]>("/wallet");
  }

  static createWalletTransaction(_data: IWalletTransaction) {
    return axios.post<never, IDbUser[]>(`/wallet`, {
      ..._data,
    });
  }

  static getWalletTransactions(userId: string, excludeDeleted?: boolean) {
    return axios.get<never, IDbUser[]>(`/wallet/${userId}`, { params: { excludeDeleted } });
  }

  static deleteWalletTransaction(transactionId: string) {
    return axios.delete<never, IDbUser[]>("/wallet/", { data: { transactionId } });
  }
  static async deleteUser(userId: string) {
    return axios.delete<never, IDbUser[]>("/users", { data: { userId } });
  }

  static async updateSuperAdminType(userId: string) {
    return axios.put<never, IDbUser[]>("/users/updateSuperAdminType", { userId });
  }

  static async getStudents() {
    return axios.get<never, IStudent[]>("/students");
  }
  static async createUser(data: IDbUser) {
    return axios.post<never, IDbUser>("/users", data);
  }
  // editUser

  static async editUser(data: IDbUser) {
    return axios.put<never, IDbUser>("/users", data);
  }
  static async createStudent(data: studentType) {
    return axios.post<never, IStudent[]>("/students", data);
  }
}

export default Api;
