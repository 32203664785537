import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../modules/service/api";
import { RootState } from "./store";
import { defaultErrorMessage, IDbUser, IWalletTransaction } from "./interfaces";

const initialState: {
  user: IDbUser | null;
  isLoading: boolean;
  isLoadingParents: boolean;
  errorMessage: string;
  allUsers: IDbUser[];
  isLoadingWalletTransactions: boolean;
  isUpdatingTransaction: boolean;
  userWalletTransactions?: IWalletTransaction[];
} = {
  user: null,
  isLoading: true,
  isLoadingParents: false,
  errorMessage: "",
  allUsers: [],
  isLoadingWalletTransactions: false,
  userWalletTransactions: [],
  isUpdatingTransaction: false,
};

export const getUser = createAsyncThunk<IDbUser[], void, { rejectValue: string }>(
  "wallet/getUsers",
  async (_, { rejectWithValue }) => {
    try {
      console.log("calling db.....");
      const ret = await Api.getUsers();
      return ret;
    } catch (e) {
      return rejectWithValue(e as string);
    }
  }
);

export const getUserWallet = createAsyncThunk<IDbUser[], void, { rejectValue: string }>(
  "wallet/getUserWallet",
  async (data, { rejectWithValue }) => {
    try {
      const ret = await Api.getUserWallet();
      return ret;
    } catch (e) {
      return rejectWithValue(e as string);
    }
  }
);

export const createWalletTransaction = createAsyncThunk<IDbUser[], IWalletTransaction, { rejectValue: string }>(
  "wallet/postUser",
  async (_data, { rejectWithValue }) => {
    try {
      const ret = await Api.createWalletTransaction(_data);
      return ret;
    } catch (e) {
      return rejectWithValue(e as string);
    }
  }
);

export const getWalletTransactions = createAsyncThunk<
  IDbUser[],
  { userId: string; excludeDeleted?: boolean },
  { rejectValue: string }
>("wallet/getWalletTransactions", async ({ userId, excludeDeleted }, { rejectWithValue }) => {
  try {
    const ret = await Api.getWalletTransactions(userId, excludeDeleted);
    return ret;
  } catch (e) {
    return rejectWithValue(e as string);
  }
});

export const deleteWalletTransaction = createAsyncThunk<IDbUser[], string, { rejectValue: string }>(
  "wallet/deleteWalletTransaction",
  async (transactionId, { rejectWithValue }) => {
    try {
      const ret = await Api.deleteWalletTransaction(transactionId);
      return ret;
    } catch (e) {
      return rejectWithValue(e as string);
    }
  }
);

// export const adminUpdateUserAccountType = createAsyncThunk<IDbUser[], IDbUser, { rejectValue: string }>(
//   "wallet/adminUpdateUserAccountType",
//   async (_data: IDbUser, { rejectWithValue }) => {
//     try {
//       const ret = await Api.adminUpdateUserAccountType(_data);
//       return ret;
//     } catch (e) {
//       return rejectWithValue(e as string);
//     }
//   }
// );
export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    reset: () => {},
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state, { payload }) => {
      console.log("got user....");
      state.isLoading = false;
      state.user = payload[0];
    });
    builder.addCase(getUser.pending, (state, _) => {
      console.log("pending user....");
      state.isLoading = true;
    });
    builder.addCase(getUser.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errorMessage = payload ?? defaultErrorMessage;
      console.log(state.errorMessage);
    });
    //
    // Get users
    builder.addCase(getUserWallet.fulfilled, (state, { payload }) => {
      state.isLoadingParents = false;
      state.allUsers = payload;
    });
    builder.addCase(getUserWallet.pending, (state, _) => {
      state.isLoadingParents = true;
    });
    builder.addCase(getUserWallet.rejected, (state, { payload }) => {
      state.errorMessage = payload ?? defaultErrorMessage;
      state.isLoadingParents = false;
      console.log(state.errorMessage);
    });

    // Create transaction
    builder.addCase(createWalletTransaction.fulfilled, (state, { payload }) => {
      state.isLoadingParents = false;
      state.allUsers = payload;
    });
    builder.addCase(createWalletTransaction.pending, (state, _) => {
      state.isLoadingParents = true;
    });
    builder.addCase(createWalletTransaction.rejected, (state, { payload }) => {
      state.errorMessage = payload ?? defaultErrorMessage;
      state.isLoadingParents = false;
      console.log(state.errorMessage);
    });

    // get user wallet transactions
    builder.addCase(getWalletTransactions.fulfilled, (state, { payload }) => {
      state.isLoadingWalletTransactions = false;
      state.userWalletTransactions = payload[0]?.transactions;
    });
    builder.addCase(getWalletTransactions.pending, (state, _) => {
      state.isLoadingWalletTransactions = true;
    });
    builder.addCase(getWalletTransactions.rejected, (state, { payload }) => {
      state.errorMessage = payload ?? defaultErrorMessage;
      state.isLoadingWalletTransactions = false;
      console.log(state.errorMessage);
    });

    // Delete row
    builder.addCase(deleteWalletTransaction.fulfilled, (state, { payload }) => {
      state.isUpdatingTransaction = false;
      state.allUsers = payload;
    });
    builder.addCase(deleteWalletTransaction.pending, (state, _) => {
      state.isUpdatingTransaction = true;
    });
    builder.addCase(deleteWalletTransaction.rejected, (state, { payload }) => {
      state.errorMessage = payload ?? defaultErrorMessage;
      state.isUpdatingTransaction = false;
      console.log(state.errorMessage);
    });
  },
});
export const isLoading = (state: RootState) => state.wallet.isLoading;
export const isLoadingParents = (state: RootState) => state.wallet.isLoadingParents;
export const selectUser = (state: RootState) => state.wallet.user;
export const allUsers = (state: RootState) => state.wallet.allUsers;
export const errorMessage = (state: RootState) => state.wallet.errorMessage;
export const isLoadingWalletTransactions = (state: RootState) => state.wallet.isLoadingWalletTransactions;
export const isUpdatingTransaction = (state: RootState) => state.wallet.isUpdatingTransaction;
export const userWalletTransactions = (state: RootState) => state.wallet.userWalletTransactions;
export const { reset } = walletSlice.actions;

export default walletSlice.reducer;
